import { TRAININGS_LIMIT_PER_PAGE } from '../constants';
import { ConferenceTypes, CourseTypes } from '../interfaces/Filter';
import coreApiService from './core.service';

const GET_UPCOMING_EVENTS = 'pv/event/general/calendar/list';
const GET_ARCHIVE_VIDEOS = 'pv/event/video/latest/course';
const GET_MY_PV_FAVOURITE = 'pv/user/my-favorite';
const GET_MY_PV_COURSES = 'pv/user/my-courses';
const GET_MY_PV_CONFERENCES = 'pv/user/my-conferences';
const GET_MY_PV_VIDEOS = 'pv/user/my-videos';
const GET_MY_PV_NOTIFICATION = 'pv/notification/list';
const DELETE_MY_PV_NOTIFICATION = 'pv/notification';
const GET_MY_PV_MATERIALS = 'pv/user/my-materials';
const GET_ACADEMIES = 'pv/module/list';
const GET_ACADEMY = 'pv/module/slug';
const GET_TRAININGS = 'pv/event/course/list';
const GET_TRAINING_BY_KEY = 'pv/event/course/key';
const GET_TRAINING = 'pv/event/course/slug';
const GET_CONFERENCES = 'pv/event/conference/list';
const GET_CONFRENCE = 'pv/event/conference';
const GET_CONFERENCE_BY_KEY = 'pv/event/conference/key';
const GET_HOMEPAGE_BANNERS = 'banner/homepage';
const GET_COURSES_BANNERS = 'banner/courses';
const GET_CONFERENCES_BANNERS = 'banner/conferences';
const ADD_COURSE_TO_FAVORITE = 'pv/user/favorite/course';
const DELETE_COURSE_FROM_FAVORITE = 'pv/user/favorite/course';
const ADD_CONFERENCE_TO_FAVORITE = 'pv/user/favorite/conference';
const UPDATE_WATCHED_TIME_TO_VIDEO = 'pv/event/video';
const DELETE_CONFERENCE_FROM_FAVORITE = 'pv/user/favorite/conference';
const SET_MY_NOTIFICATION_READ = 'pv/notification';
const SEARCH = 'search?page=0&size=5';
const GET_DATE = 'pv/event/date';
const GET_PERSON = 'pv/person';
const GET_GALLERY = 'pv/media/image/gallery';
const SEND_ORDER_FORM = 'pv/contact/order-form';
const STREAM_STATUS = 'pv/event/stream';
const SITEMAP = 'pv/sitemap';

export const calendarApi = {
    getUpComingEvents: (date: string, token: string, count?: number, offset?: number) =>
        coreApiService.get(
            GET_UPCOMING_EVENTS,
            {
                limit: count || 6,
                offset: offset || 0,
                date,
            },
            token
        ),
    getArchiveVideos: (count?: number) =>
        coreApiService.get(GET_ARCHIVE_VIDEOS, {
            limit: count || 3,
            offset: 0,
        }),
    getAcademies: (searchStr?: string) => coreApiService.get(GET_ACADEMIES, { ...(searchStr && { query: searchStr }) }),
    getAcademy: (slug: string, token: string) => coreApiService.get(`${GET_ACADEMY}/${slug}`, {}, token),
    getStreamStatus: (streamId: string) => coreApiService.get(`${STREAM_STATUS}/${streamId}/info`),
    getMyNotifications: (limit?: number, offset?: number) => coreApiService.get(GET_MY_PV_NOTIFICATION, { limit }),
    deleteMyNotification: (key?: string) => coreApiService.delete(`${DELETE_MY_PV_NOTIFICATION}/${key}`),
    getMyFavourite: (
        limit?: number | string | string[],
        offset?: number | string | string[],
        search?: string | string[]
    ) => coreApiService.get(GET_MY_PV_FAVOURITE, { limit: limit || 10, offset: offset || 0, query: search }),
    getMyConferences: (
        limit?: number | string | string[],
        offset?: number | string | string[],
        search?: string | string[],
        city?: string | string[],
        lecturer?: string | string[],
        type?: string | string[],
        archived?: boolean,
        upcoming?: boolean
    ) => {
        const filteredType = (type ? String(type) : '')
            ?.split(',')
            ?.filter((type) => ![ConferenceTypes.ARCHIVED, ConferenceTypes.UPCOMING].includes(type as ConferenceTypes))
            ?.join(',');

        return coreApiService.get(GET_MY_PV_CONFERENCES, {
            limit: limit || 10,
            offset: offset || 0,
            query: search,
            venues: city,
            people: lecturer,
            ...(filteredType && { type: filteredType }),
            upcoming: !archived || upcoming,
            archived: archived,
        });
    },
    getMyMaterials: (
        limit?: number | string | string[],
        offset?: number | string | string[],
        search?: string | string[]
    ) => coreApiService.get(GET_MY_PV_MATERIALS, { limit: limit || 10, offset: offset || 0, query: search }),
    getMyCourses: (
        limit?: number | string | string[],
        offset?: number | string | string[],
        search?: string | string[],
        city?: string | string[],
        lecturer?: string | string[],
        category?: string | string[],
        type?: string | string[],
        month?: string | string[]
    ) =>
        coreApiService.get(GET_MY_PV_COURSES, {
            limit: limit || 10,
            offset: offset || 0,
            query: search,
            venues: city,
            people: lecturer,
            categories: category,
            months: month,
            upcoming: !type?.includes(CourseTypes.ARCHIVED),
            online: !!type?.includes(CourseTypes.ONLINE),
            attendance: !!type?.includes(CourseTypes.ATTENDANCE),
            experience: !!type?.includes(CourseTypes.EXPERIENCE),
            archived: !!type?.includes(CourseTypes.ARCHIVED),
        }),
    getMyCoursesForGuest: (token: string) => coreApiService.get(GET_MY_PV_COURSES, {}, token),
    getMyConferencesForGuest: (token: string) => coreApiService.get(GET_MY_PV_CONFERENCES, {}, token),
    getTrainings: (
        limit?: number,
        offset?: number,
        search?: string,
        city?: string,
        lecturer?: string,
        category?: string,
        type?: string,
        month?: string,
        token?: string
    ) =>
        coreApiService.get(
            GET_TRAININGS,
            {
                limit: limit || TRAININGS_LIMIT_PER_PAGE,
                offset: offset || 0,
                query: search,
                venues: city,
                people: lecturer,
                categories: category,
                months: month,
                upcoming: !type?.includes(CourseTypes.ARCHIVED),
                online: !!type?.includes(CourseTypes.ONLINE),
                attendance: !!type?.includes(CourseTypes.ATTENDANCE),
                experience: !!type?.includes(CourseTypes.EXPERIENCE),
                archived: !!type?.includes(CourseTypes.ARCHIVED),
            },
            token
        ),
    setMyNotificationRead: (key: string) => coreApiService.post(`${SET_MY_NOTIFICATION_READ}/${key}/read`, {}),
    getTraining: (slug: string, token: string) => coreApiService.get(`${GET_TRAINING}/${slug}`, {}, token),
    getTrainingByKey: (key: string, token: string) => coreApiService.get(`${GET_TRAINING_BY_KEY}/${key}`, {}, token),
    getTrainingDates: (slug: string, token: string) =>
        coreApiService.get(`${GET_TRAINING}/${slug}/date/list`, {}, token),
    getTrainingLecturers: (slug: string, token: string) =>
        coreApiService.get(`${GET_TRAINING}/${slug}/person/list`, {}, token),
    getTrainingModules: (slug: string, token: string) =>
        coreApiService.get(`${GET_TRAINING}/${slug}/module/list`, {}, token),
    getHomepageBanners: () => coreApiService.get(GET_HOMEPAGE_BANNERS),
    getCoursesBanners: () => coreApiService.get(GET_COURSES_BANNERS),
    getConferencesBanner: () => coreApiService.get(GET_CONFERENCES_BANNERS),
    addCourseToFavourite: (key: string) => coreApiService.put(ADD_COURSE_TO_FAVORITE, { _key: key }),
    deleteCourseFromFavourite: (key: string) => coreApiService.delete(`${DELETE_COURSE_FROM_FAVORITE}/${key}`),
    addConferenceToFavourite: (key: string) => coreApiService.put(ADD_CONFERENCE_TO_FAVORITE, { _key: key }),
    deleteConferenceFromFavourite: (key: string) => coreApiService.delete(`${DELETE_CONFERENCE_FROM_FAVORITE}/${key}`),
    updateWatchedTimeToVideo: (key: string, time: number) =>
        coreApiService.put(`${UPDATE_WATCHED_TIME_TO_VIDEO}/${key}/watchtime`, { timeWatched: time }),
    getConferences: (
        limit?: number,
        offset?: number,
        search?: string,
        city?: string,
        lecturer?: string,
        type?: string,
        archived?: boolean,
        upcoming?: boolean,
        token?: string
    ) => {
        const filteredType = type
            ?.split(',')
            ?.filter((type) => ![ConferenceTypes.ARCHIVED, ConferenceTypes.UPCOMING].includes(type as ConferenceTypes))
            ?.join(',');

        return coreApiService.get(
            GET_CONFERENCES,
            {
                limit: limit || 10,
                offset: offset || 0,
                query: search,
                venues: city,
                people: lecturer,
                ...(filteredType && { type: filteredType }),
                upcoming: !archived || upcoming,
                archived: archived,
            },
            token
        );
    },
    search: (searchPhrase: string | string[], filters: { city: string[]; person: string[] }) =>
        coreApiService.post(SEARCH, filters, {}, { searchPhrase }),
    getConferenceKey: (key: string) => coreApiService.get(`${GET_CONFRENCE}/${key}`, {}),
    getConferenceSlug: (slug: string, token: string) => coreApiService.get(`${GET_CONFRENCE}/slug/${slug}`, {}, token),
    getConferenceKeyAndToken: (key: string, token: string) =>
        coreApiService.get(`${GET_CONFERENCE_BY_KEY}/${key}`, {}, token),
    getConferenceDates: (slug: string, token: string) =>
        coreApiService.get(`${GET_CONFRENCE}/slug/${slug}/date/list`, {}, token),
    getDateData: (key: string) => coreApiService.get(`${GET_DATE}/${key}`, {}),
    getPersonData: (key: string) => coreApiService.get(`${GET_PERSON}/${key}`, {}),
    getSitemapSlugs: () => coreApiService.get(SITEMAP, {}),
    getGalleryImages: (key: string, limit?: number, offset?: number) =>
        coreApiService.get(`${GET_GALLERY}/${key}/image/list`, { limit: limit || 100, offset: offset || 0 }),
    sendOrderForm: (data: any) => coreApiService.post(`${SEND_ORDER_FORM}`, data),
};
